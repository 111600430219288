.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltip-text {
  visibility: hidden;
  width: 20vw;
  min-width: 240px;
  background-color: #000000;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip-right-up {
  transform: translateY(-100%) translateY(-5px) translateX(2px);
}

.tooltip-right-down {
  transform: translateX(2px);
}

.tooltip-left-up {
  transform: translateX(-100%) translateY(-100%) translateX(20px) translateY(20px);
}

.tooltip-left-down {
  transform: translateX(-100%) translateX(-15px) translateY(2px);
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
}

.fa-question-circle {
  color: #2c3e50;
}
